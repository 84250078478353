const initialState = {
  loading: false,
  name: "",
  allTokens: [],
  walletOfOwner: [],
  maxTokensPerPurchase: "",
  maxTokenSupply: "",
  tokenMinted: "",
  error: false,
  errorMsg: "",
};

const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHECK_DATA_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    case "CHECK_DATA_SUCCESS":
      return {
        ...initialState,
        loading: false,
        name: action.payload.name,
        allTokens: action.payload.allTokens,
        walletOfOwner: action.payload.walletOfOwner,
        maxTokensPerPurchase: action.payload.maxTokensPerPurchase,
        maxTokenSupply: action.payload.maxTokenSupply,
        tokenMinted: action.payload.tokenMinted,
      };
    case "CHECK_DATA_FAILED":
      return {
        ...initialState,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default dataReducer;
