// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = (account) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let name = await store
        .getState()
        .blockchain.smartContract.methods.name()
        .call();
      let allTokens = await store
        .getState()
        .blockchain.smartContract.methods.getAllTokens()
        .call();
      let walletOfOwner = await store
          .getState()
          .blockchain.smartContract.methods.walletOfOwner(account)
          .call();
      let maxTokensPerPurchase = await store
          .getState()
          .blockchain.smartContract.methods.getMaxTokenPerPurchase()
          .call();
      let maxTokenSupply = await store
          .getState()
          .blockchain.smartContract.methods.getMaxTokenSupply()
          .call();
      let tokenMinted = await store
          .getState()
          .blockchain.smartContract.methods.getTokenMinted()
          .call();

      dispatch(
        fetchDataSuccess({
          name,
          allTokens,
          walletOfOwner,
          maxTokensPerPurchase,
          maxTokenSupply,
          tokenMinted
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
